



















import { Component, Mixins, PropSync } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import { kpis_actuacion } from "@/shared/dtos/kpis_actuacion";
import kpis_actuacionModule from "@/store/modules/kpis_actuacion-module";

@Component({
  components: {},
})
export default class KpisFormulario extends Mixins(HelperDinamicallyForm) {
  public item_pantalla: kpis_actuacion = new kpis_actuacion();
  @PropSync("id", { default: 0 }) XId!: number;
  @PropSync("show", { default: false }) XShow!: boolean;

  public async OnCreated() {
    this.ShowLoading();
    this.item_pantalla.id_actuacion = this.getparamId().Parametro;
    this.EstoyCreado = true;
    console.log(this.XId);
    if (this.XId > 0) {
      await kpis_actuacionModule.getkpis_actuacion(this.XId);
      this.item_pantalla = kpis_actuacionModule.kpis_actuacion;
      this.EstoyCreado = false;
    }
    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
    this.HideLoading();
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica

    this.AddFormItemDataDefaultString(
      this.item_pantalla.nombre,
      "Nombre",
      "nombre",
      60
    )
      .isRequired()
      .Dimesiones(12, 6, 6);

    this.AddFormItemDataDefaultString(
      this.item_pantalla.unidades,
      "Unidades",
      "unidades",
      60
    )
      .isRequired()
      .Dimesiones(12, 6, 6);
    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.resultado_previsto,
      "Resultado previsto",
      "resultado_previsto"
    )
      .isRequired()
      .Dimesiones(12, 6, 6);
    this.AddFormItemDataDefaultSlot(
      this.item_pantalla.id_actuacion,
      "Actuacion",
      "id_actuacion",
      5
    ).Dimesiones(12, 6, 6);
    this.AddFormItemDataDefaultString(
      this.item_pantalla.descripcion,
      "Descripción",
      "descripcion"
    ).CreateTextArea();
  }

  public Insertar(object: kpis_actuacion) {
    kpis_actuacionModule.guardarkpis_actuacion(object).then((x) => {
      this.$emit("refrescar", x);
      this.XShow = false;
    });
  }

  public Actualizar(object: kpis_actuacion) {
    kpis_actuacionModule.modificarkpis_actuacion(object).then((x) => {
      this.$emit("refrescar", x);
      this.XShow = false; //guardo correctamente y me cierro
    });
  }
}
